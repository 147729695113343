var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "link-line-incentive-list-item" }, [
    _c(
      "span",
      { staticClass: "link-line-incentive-list-item__icon" },
      [
        _c("Icon", {
          attrs: {
            name: "present",
            color: "white01",
            width: "24",
            height: "24",
          },
        }),
      ],
      1
    ),
    _c(
      "span",
      { staticClass: "link-line-incentive-list-item__content" },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }